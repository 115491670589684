<template>
  <el-form
    @submit.native.prevent="savaArticle"
    ref="form"
    :model="article"
    label-width="80px"
  >
    <el-form-item label="文章标题">
      <el-input v-model="article.name"></el-input>
    </el-form-item>
    <el-form-item label="文章内容">
      <el-input type="textarea" v-model="article.body"></el-input>
    </el-form-item>
    <el-form-item>
      <el-button type="primary" native-type="submit">立即创建</el-button>
      <el-button>取消</el-button>
    </el-form-item>
  </el-form>
</template>


<script>
// 可以忽略eslint检查未使用变量错误
// eslint-disable-next-line no-unused-vars
// ... 这里是你的代码 ...
</script>

<script>
// 引入去除空格工具 暂时不用
// import { validUsername } from '@/utils/validate'

import { articles } from "../../api/user";

export default {
  data() {
    return {
      article: {
        name: "",
        body: "",
      },
    };
  },
  methods: {
    // 提交文章
    savaArticle() {
      console.log(this.article);
      this.loading = true;
      if (this.article.name === "" || this.article.body === "") {
        this.$message.error("请填写文章标题和内容");
        return false;
      } else {
        articles(this.article)
          .then((res) => {
            if (res.data.code == 200) {
              this.$message.success(res.data.msg);
              this.loading = false;
              this.$router.push("/article/ListArt");
              // 设置状态码
            } else if (res.data.code == 1100) {
              this.$message.error(res.data.msg);
              this.loading = false;
            }

            // this.$router.push({ path: this.redirect || '/' })
          })
          .catch((err) => {
            // eslint-disable-line no-unused-vars
            // this.$message.error(err.response.data.msg);
            this.$message.error("报错了");
            console.log("error submit!! 报错");
            this.loading = false;
            return false;
          });
      }
    },
  },
};
</script>
